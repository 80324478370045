import { graphql } from 'gatsby';
import React from 'react';
import BlogListPage from '../components/blog/BlogListPage';
import Meta from '../components/layout/Meta';
import { BlogPost } from '../types';
import BLOG_CATEGORIES from '../utils/blogCategories';
import { fullPosts, PostsQueryResponse } from '../utils/fullPosts';

interface BlogProps {
  category: string;
  posts: BlogPost[];
}

const BlogCategoryTemplate: React.SFC<BlogProps> = ({ category, posts }) => {
  const info = BLOG_CATEGORIES.find(({ name }) => name === category);

  return (
    <>
      <Meta title={`${category} - Knock Down Silos by Slab`} />
      <BlogListPage
        description={info?.description}
        posts={posts}
        title={category}
      />
    </>
  );
};

interface PageProps extends PostsQueryResponse {
  pageContext: { category: string };
}

export default (props: PageProps) => (
  <BlogCategoryTemplate
    category={props.pageContext.category}
    posts={fullPosts(props)}
  />
);

export const query = graphql`
  query postsInCategory($category: String!) {
    posts: allMarkdownRemark(
      filter: {
        fields: { type: { eq: "blog" } }
        frontmatter: { category: { eq: $category }, hide: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...BlogListPostFragment
        }
      }
    }
    ...AuthorsQueryFragment
  }
`;
